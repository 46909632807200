<template>
    <div id="pageList" class="CxList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">车型名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入车型名称进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_NAME" label="车型名称"/>
                <el-table-column prop="F_CAPACITY" label="载重(吨)" align="right" header-align="center"/>
                <el-table-column prop="F_SIZE" label="载方(方)" align="right" header-align="center"/>
                <el-table-column prop="F_LENGTH" label="车厢长(米)" align="right" header-align="center"/>
                <el-table-column prop="F_WIDTH" label="车厢宽(米)" align="right" header-align="center"/>
                <el-table-column prop="F_HEIGHT" label="车厢高(米)" align="right" header-align="center"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import CxCard from './CxCard';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "CxList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                pageList: {
                    queryParam: {
                       name:"" 
                    },
                    modelComp: CxCard,
                    modelMethod: "/cx/pageData"
               }
            })
            onMounted(()=>{
            })
            //---------------------------computed------------

            return{
                ...toRefs(dataObj)
            }
        }
    });
</script>

<style scoped>
    .CxList{
        width: 100%;
    }
</style>